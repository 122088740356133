import Config from './Config'
import { OktaAuth } from '@okta/okta-auth-js'

const OktaConfig = {
  issuer: Config.OKTA_ISSUER,
  redirectUri: `${window.location.origin}/implicit/callback`,
  clientId: 'nike.niketech.niftesting',
  pkce: true,
}

const oktaAuth = new OktaAuth(OktaConfig)

export const getOktaAuth = () => oktaAuth

export default OktaConfig