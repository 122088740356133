import React from "react";
import {Constants} from "../Constants";
import {AppHeader, Button} from "@nike/eds/dist";
import {MessageSelector} from "./MessageSelector";
import {FlowSelector} from "./FlowSelector";
import {JSONEditor} from "./JSONEditor";
import {NikeApp, Play} from "@nike/nike-design-system-icons";
import { Card } from "@nike/eds";
import {  Snack, Snackbar } from "@nike/eds";
import { Spinner } from "@nike/eds";
import {FetchService} from "./FetchService";

function Header() {

    return (
        <AppHeader
            isDark
            appName="Node Integration Framework - Test UI"
            logoSlot={<NikeApp color={"white"} width={"56px"} height={"64px"} />}
        />
    );
}


class Home extends React.Component {

    constructor(props) {
        super(props);
        this.jsonEditor = React.createRef()
        this.state = {
            messageFlow: "",
            messageType: "",
            textArea:'{"Select the desired payload format or insert your custom payload": "0"}',
            show: false,
            snackBarText: "",
            loading: false
        };

        this.selectMessageFlow = this.selectMessageFlow.bind(this);
        this.selectMessageType = this.selectMessageType.bind(this);
                this.payloadChangedUpdateState = this.payloadChangedUpdateState.bind(this);

    }
    updateTextArea(){
        let url = Constants.APIURL  + "/payloads/" + this.state.messageFlow + "/" + this.state.messageType;
        FetchService(url, "GET", "").then(response => {response.json().then(json => {
               this.setState({textArea: JSON.parse(json.content)})
               this.jsonEditor.current.update()

         })
        });

      }

    sendPayload(){
        let url = Constants.APIURL + "/requests/v1";
        this.setState({loading: true});

        FetchService(url, "POST", this.getPayload())
            .then(r => this.handlePayload(r));
    }
    handlePayload(response) {
        if (response.ok) {
            response.json().then(json => {
                this.setState({
                    "error": false,
                    "show": true,
                    "snackBarText": json.conversationId,
                    "loading": false
                });
            })
        } else {

            this.setState({
                "error": true,
                "show": true,
                "snackBarText": response.status + " " + response.statusText,
                "loading": false
            });
        }
    }

    getPayload(){
        let payload = {};
        payload.payload = JSON.stringify(this.state.textArea);
        payload.messageType = this.state.messageType;
        payload.flowType = this.state.messageFlow;
        return JSON.stringify(payload);
    }

    selectMessageFlow(messageFlow) {
        this.setState({
            messageFlow: messageFlow
        }, function() {this.updateTextArea()});
    }

    selectMessageType(messageType) {
        this.setState({
            messageType: messageType
        }, function() {this.updateTextArea()});
    }

    payloadChangedUpdateState(e){
        this.setState({
            textArea: e
        });
    }

    render() {
        return (
            <div>
                <Header />
                <div className="eds-grid eds-grid--m-cols-4">
                    <Card className="eds-grid--col-full">
                        <Card className="eds-card eds-grid--rows-4 eds-elevation--4">
                            <FlowSelector onSelect={this.selectMessageFlow}/>
                            <MessageSelector flow={this.state.messageFlow} onSelect={this.selectMessageType}/>
                        </Card>
                        <br/>
                        <Card className="eds-card eds-elevation--4">
                            <JSONEditor payLoadChanged={this.payloadChangedUpdateState} ref={this.jsonEditor} flow = {this.state.messageFlow} textArea = {this.state.textArea} />
                            <br/>
                            <Button beforeSlot={ this.state.loading ?  <></>: <Play></Play>} size="medium"   disabled={this.state.loading} variant="primary" onClick={() => {this.sendPayload()}}>
                                 { this.state.loading  ? <Spinner size="medium" /> : "Submit payload"}
                            </Button>

                            <Snackbar>
                              {this.state.show && !this.state.error && (
                                <Snack id="1" status="success" onDismiss={id => this.setState({"show": false})} >
                                    View details of request in <a style={{textDecoration: 'underline'}} target="_blank" rel="noreferrer" href={`https://app.signalfx.com/#/apm/traces/${this.state.snackBarText}`}>SignalFX</a>
                                </Snack>
                              )}
                               {this.state.show && this.state.error && (
                                  <Snack id="1" status="error" onDismiss={id => this.setState({"show": false})} >
                                    Something went wrong sending payload. Server responded with {this.state.snackBarText}
                                  </Snack>
                                )}
                            </Snackbar>
                        </Card>
                    </Card>
                </div>
            </div>
        );
    }

}

export { Home };
