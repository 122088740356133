import React from "react";
import {Button, Icon, Menu, MenuItem} from "@nike/eds";
import {Constants} from "../Constants";
import {CaretDown, CaretUp} from "@nike/nike-design-system-icons";
import {FetchService} from "./FetchService";


class FlowSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            integrationConfigs: []
        };
    }

    componentDidMount() {
        let url = Constants.APIURL+ "/configs";
        FetchService(url, "GET", "").then(response => {response.json().then(json => {this.setState({integrationConfigs: json})})
            });
    }

    setOpen(open) {
        this.setState({"open": open})
    }

    render() {
        return (
            <Menu
                isDark={true}
                onClickOutside={() => {
                    this.setOpen(!this.state.open);
                }}
                isOpen={this.state.open}
                bodySlot={
                    this.state.integrationConfigs.map(integrationConfig => (
                        <MenuItem onClick={() => {this.setState({'flow': integrationConfig.flow}); this.props.onSelect(integrationConfig.flow)}}
                                  beforeSlot={this.state.flow === integrationConfig.flow ? <Icon name="Check" /> : <></>}>
                            {integrationConfig.flow}
                        </MenuItem>
                    ))
                }
                placement={"bottom-start"}
            >
                <Button
                    beforeSlot={this.state.open ? <CaretUp /> : <CaretDown />}
                    variant="secondary"
                    onClick={() => this.setOpen(!this.state.open)}>
                    {this.state.flow ? this.state.flow : "Select flow"}
                </Button>
            </Menu>
        )

    }

}

export { FlowSelector };
