import React from "react";
import {Button, Icon, Menu, MenuItem} from "@nike/eds";
import {CaretDown, CaretUp} from "@nike/nike-design-system-icons";
import {Constants} from "../Constants";
import {FetchService} from "./FetchService";

class MessageSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            integrationConfigs: []
        };
    }

    componentDidMount() {
        let url = Constants.APIURL+ "/configs";
        FetchService(url, "GET", "").then(response => {response.json().then(json => {this.setState({integrationConfigs: json})})
        });
    }

    setOpen(open) {
        this.setState({"open": open})
    }

    render() {
        return (
            <Menu
                isDark={true}
                onClickOutside={() => {
                    this.setOpen(!this.state.open);
                }}
                isOpen={this.state.open}
                bodySlot={
                    this.state.integrationConfigs.map(integrationConfig => (
                        integrationConfig.messageTypes.map(messageType => (
                            this.props.flow === integrationConfig.flow &&
                            <MenuItem
                                beforeSlot={this.state.messageType === messageType ? <Icon name="Check" /> : <></>}
                                onClick={() => {this.setState({'messageType': messageType}); this.props.onSelect(messageType)}}>
                                {messageType}
                            </MenuItem>
                        ))
                    ))
                }
                placement={"bottom-start"}
            >
                <Button
                    beforeSlot={this.state.open ? <CaretUp /> : <CaretDown />}
                    variant="secondary"
                    onClick={() => this.setOpen(!this.state.open)}>
                    {this.state.messageType ? this.state.messageType : "Select message"}
                </Button>
            </Menu>
        )
    }
}

export { MessageSelector };
